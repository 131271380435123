import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { format } from "date-fns"
import queryString from "query-string"
import Spinner from "./components/spinner"
import { FormType } from "../../types"
import { postLead } from "../../api/leads"
import { getUniversalId } from "../../api/uinversal-id"
import useSiteMetadata from "../../hooks/use-site-metadata"
import lookUpState from "../../../utils/look-up-state"
import { trimmedGaCookieValue } from "../../../utils/look-up-ga-cookie"

type FormProps = {
  form: FormType
  [key: string]: any
}

type FormData = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  zipCode: string
}

const LeadFormv2 = ({ form, ...props }: FormProps) => {
  const search = props?.location?.search
  const parsedSearch = queryString.parse(search)
  const { siteUrl, environment } = useSiteMetadata()
  const [stateData, setData] = useState({
    isSubmitting: false,
    zipCode: parsedSearch.zipCode,
  })
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  const firstNameClasses = `mb-1 p-3 block w-full bg-inputBackground ${errors.firstName ? `border-red-500 border-2` : ``}`
  const lastNameClasses = `mb-1 p-3 block w-full bg-inputBackground ${errors.lastName ? `border-red-500 border-2` : ``}`
  const emailClasses = `mb-1 p-3 block w-full bg-inputBackground ${errors.email ? `border-red-500 border-2` : ``}`
  const phoneNumberClasses = `mb-1 p-3 block w-full bg-inputBackground ${errors.phoneNumber ? `border-red-500 border-2` : ``}`
  const zipCodeClasses = `mb-1 p-3 block w-full bg-inputBackground ${errors.zipCode ? `border-red-500 border-2` : ``}`
  const buttonClasses = `p-3 w-full button ${stateData.isSubmitting ? `disabled` : `primary`}`

  const wrapGetUniversalId = async (data: FormData): Promise<string | undefined> => {
    try {
      const universalId = await getUniversalId({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        income: undefined,
        state: undefined,
        zipCode: data.zipCode,
        dateOfBirth: undefined,
        familySize: `1`,
        leadSourceDomain: siteUrl,
        environment: environment,
      })

      return universalId
    } catch (err) {
      window.datadogLogs?.logger.error(`submit universal id error:`, body)
    }

    return undefined
  }

  const onSubmit = async (data: FormData) => {
    setData({ ...stateData, isSubmitting: true })

    const stateValue = lookUpState(`${data.zipCode || `0`}`)

    try {
      postLead({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        zipCode: data.zipCode,
        familySize: `1`,
        leadSourceDomain: siteUrl,
        income: undefined,
        state: stateValue,
        dateOfBirth: undefined,
        environment: environment,
      })
        .then(async function (result) {
          if (result) {
            const universalId = await wrapGetUniversalId(data)

            // GA event logging
            if (typeof marketingDataLayer !== `undefined`) {
              universalId &&
                marketingDataLayer?.push({
                  universalID: universalId,
                })

              marketingDataLayer?.push({
                event: `gaForm`,
                eventCategory: `Form`,
                eventAction: `lead-form-v2`,
                eventLabel: location.pathname,
              })
            }

            // Tealium event logging
            if (typeof utag !== `undefined`) {
              console.log(`Tealium is loaded`)

              utag.link({
                customer_birthdate: "", //Required
                customer_email: `${data.email}`, //Required
                customer_first_name: `${data.firstName}`, //Required
                customer_id: trimmedGaCookieValue(), //Required (RTRIM value of _ga Cookie)
                customer_last_name: `${data.lastName}`, //Required
                customer_name: "", //Required
                customer_phone_number: `${data.phoneNumber}`, //Required
                customer_postal_code: `${data.zipCode}`, //Required
                customer_state: stateValue, //Required
                customer_type: "family", //Required
                universal_id: universalId,
                form_id: "", //Required
                form_name: "Inquiry Form", //Required
                tealium_event: "form_complete", //Required
              })
            }

            location.assign(`${form.destination}`)
          }

          setData({ ...stateData, isSubmitting: false })
        })
        .catch(function (err) {
          setData({ ...stateData, isSubmitting: false })
          alert(`Your request was not sent successfully.  ${err.message}`)
        })
    } catch (err) {
      setData({ ...stateData, isSubmitting: false })
      alert(`Your request was not sent successfully.  ${err.message}`)
    }
  }

  return (
    <form id="lead-form-v2" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid sm:grid-cols-1 lg:grid-cols-1 gap-2">
        <input {...register("firstName", { required: true })} type="text" className={firstNameClasses} placeholder="First Name" autoComplete="given-name" autoFocus />

        <input {...register("lastName", { required: true })} type="text" className={lastNameClasses} placeholder="Last Name" autoComplete="family-name" />

        <input {...register("email", { required: true })} type="email" className={emailClasses} placeholder="Email" autoComplete="email" />

        <input {...register("phoneNumber", { required: true, minLength: 7 })} type="tel" className={phoneNumberClasses} placeholder="Phone" autoComplete="tel" />

        <input
          {...register("zipCode", { required: true, maxLength: 5, minLength: 4 })}
          type="number"
          className={zipCodeClasses}
          placeholder="Zip Code"
          autoComplete="postal-code"
          value={stateData.zipCode}
          onChange={(e: { target: { value: any } }) => {
            setData({ ...stateData, zipCode: e.target.value })
            setValue("zipCode", e.target.value)
          }}
        />
      </div>

      <div className="text-center mt-5">
        {Object.keys(errors).length > 0 && (
          <div className="bg-red-400 p-3 mb-2 text-white rounded">{(errors.consent && Object.keys(errors).length === 1 && `Please accept the terms.`) || `Please fix errors in your form.`}</div>
        )}

        <button type="submit" className={buttonClasses} disabled={stateData.isSubmitting}>
          {stateData.isSubmitting && <Spinner className="float-left h-7 w-5 text-white" size={5} color="white" />}
          {form.submitText || `Submit`}
        </button>
      </div>
    </form>
  )
}

export default LeadFormv2
